<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :fill-color="color"
        :viewbox-width="10"
        :viewbox-height="10"
        @click="$emit('click')"
    >
        <path
            style=" stroke:none;fill-rule:evenodd;fill-opacity:1;"
            d="M 10 1.953125 L 3.195312 9.167969 L 0 5.394531 L 0.316406 5.125 L 3.214844 8.542969 L 9.695312 1.667969 Z M 10 1.953125 "
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'CheckIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>
