<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <circle class="a" cx="12" cy="7.5" r="3" />
        <path
            class="a"
            d="M12,.75A6.75,6.75,0,0,1,18.75,7.5c0,3.251-5.132,10.527-6.446,12.337a.377.377,0,0,1-.608,0C10.382,18.027,5.25,10.751,5.25,7.5A6.75,6.75,0,0,1,12,.75Z"
        />
        <path
            class="a"
            d="M17.979,17.784c2.732.541,4.521,1.444,4.521,2.466,0,1.657-4.7,3-10.5,3s-10.5-1.343-10.5-3c0-1.02,1.781-1.921,4.5-2.463"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'PinLocationIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
