<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :viewBox="`0 0 ${viewboxWidth} ${viewboxHeight}`"
        :aria-labelledby="name"
        role="presentation"
        @click="$emit('click')"
    >
        <title v-if="name" :id="name">{{ name }}</title>
        <g :stroke="color" :fill="fillColor">
            <slot />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'Icon',
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
        fillColor: {
            type: String,
            default: '#000',
        },
        viewboxHeight: {
            type: [Number, String],
            default: 24,
        },
        viewboxWidth: {
            type: [Number, String],
            default: 24,
        },
    },
}
</script>
