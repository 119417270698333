<template>
    <div class="l-inline l-center simulation-mode-bar">
        <span>
            <strong>{{ $t('title') }}</strong>

            {{ $t('hint') }}

            <strong :class="{ 'username-label': usersSortedByUsername.length }">
                {{ userInfo.username }}
            </strong>
        </span>

        <user-select
            v-if="usersSortedByUsername.length"
            v-model="selectedUser"
            show-details
            @input="handleUserSelect"
        />

        <IconButton class="exit-button" :title="$t('stop')" @click="handleExit">
            <RemoveIcon color="rgba(0, 0, 0, 0.675)" />
        </IconButton>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import IconButton from './IconButton'
import RemoveIcon from './icons/RemoveIcon'
import UserSelect from './UserSelect'

export default {
    name: 'SimulationModeBar',
    components: {
        IconButton,
        RemoveIcon,
        UserSelect,
    },
    data() {
        return {
            selectedUser: null,
        }
    },
    computed: {
        ...mapState('auth', ['userInfo']),
        ...mapGetters('user', ['usersSortedByUsername']),
    },
    watch: {
        userInfo({ id }) {
            this.selectedUser = id
        },
    },
    mounted() {
        this.selectedUser = this.userInfo?.id
    },
    methods: {
        ...mapActions('auth', ['simulateUser', 'stopSimulatingUser']),
        ...mapActions('common', ['loadData']),
        ...mapMutations('common', ['setDataLoading']),
        async handleUserSelect(id) {
            this.setDataLoading(true)
            await this.simulateUser(id)
            this.$root.$i18n.locale = this.userInfo.language
            await this.loadData()
            this.$router.push('/')
        },
        handleExit() {
            this.stopSimulatingUser()
            this.$root.$i18n.locale = this.userInfo.language
            this.loadData()
        },
    },
}
</script>

<i18n>
{
    "en": {
        "hint": "Your actions will be considered as user ",
        "stop": "Exit simulation mode",
        "title": "User Simulation."
    },
    "de": {
        "hint": "Aktionen als folgenden Benutzer ausführen ",
        "stop": "Benutzer Simulation beenden",
        "title": "Benutzer Simulation."
    },
    "fr": {
        "hint": "Vos actions seront considérées comme celles d'un utilisateur",
        "stop": "Quitter le mode simulation",
        "title": "Mode Simulation"
    },
    "it": {
        "hint": "Le vostre azioni saranno considerate come utente ",
        "stop": "Uscire dal modo simulazione",
        "title": "Modo simulazione."
    }
}
</i18n>

<style lang="scss" scoped>
.simulation-mode-bar {
    padding: 0 3rem 0 1rem;
    height: 5vh;
    background-color: rgb(250, 225, 150);
    border-bottom: 1px solid rgb(215, 195, 130);
    color: rgba(0, 0, 0, 0.675);
    z-index: 1000;
}

.exit-button {
    position: absolute;
    right: 1rem;
}

.username-label {
    display: none;
    @include respond-to('for-mobile-down') {
        display: initial;
    }
}

.multiselect {
    @include respond-to('for-mobile-down') {
        display: none;
    }
}
</style>

<style lang="scss">
.simulation-mode-bar {
    .multiselect {
        margin-left: 0.5em;
        max-width: 400px;
        min-height: 32px;
    }

    .multiselect__tags {
        min-height: 32px;
        padding: 0 40px 0 8px;
    }

    .multiselect__select {
        height: 28px;
    }

    .multiselect__input,
    .multiselect__single {
        margin: 0;
        padding: 5px !important;
    }
}
</style>
