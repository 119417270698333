import { commonService } from '@/service/store.service'

const state = {
    demoType: 'ax-track',
    isDataLoading: false,
}

const getters = {}

const actions = {
    async loadData({ commit, rootState }) {
        if (!rootState.auth.jwt) {
            return
        }

        commit('setDataLoading', true)
        await commonService.populateData()
        commit('setDataLoading', false)
    },
}

const mutations = {
    setDataLoading(state, data) {
        state.isDataLoading = data
    },
    setDemoType(state, data) {
        state.demoType = data
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
