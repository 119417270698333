const measurements = [
    'angle',
    'barometric_pressure',
    'battery',
    'battery_status',
    'battery_voltage',
    'co2',
    'distance',
    'distance_to_service',
    'ev_battery_health',
    'ev_battery_percentage',
    'ev_charging_state',
    'external_voltage',
    'fill_level',
    'fuel_level',
    'fuel_rate',
    'fuel_total',
    'humidity',
    'humidity_absolute',
    'level',
    'lightning_average_distance',
    'lightning_strike_count',
    'mass',
    'maximum_wind_speed',
    'moisture',
    'odometer',
    'power',
    'precipitation',
    'running_time',
    'solar_radiation',
    'speed',
    'tachograph_odometer',
    'temperature',
    'vapor_pressure',
    'voc',
    'voltage',
    'volume',
    'wind_direction',
    'wind_speed',
]

const measurementsAnalogChannels = [
    'ai1',
    'ai2',
    'ai3',
    'ai4',
    'ai5',
    'ai6',
    'ai7',
    'ai8',
]

const measurementsSalt = [
    'mass_brine',
    'mass_salt',
    'mass_sand',
    'spread_density_brine',
    'spread_density_salt',
    'spread_density_sand',
    'spread_distance',
    'spread_running_time',
    'spread_width',
    'spreading_on',
]

const measurementsStaffOnly = [
    'lower_loop',
    'potential',
    'probe_state',
    'tau',
    'upper_loop',
    'water_level',
]

const measurementsState = [
    'd1',
    'd2',
    'd3',
    'd4',
    'd5',
    'd6',
    'sensor_attached',
]

const measurementsTemperature = ['t1', 't2', 't3', 't4', 't5', 't6']

const measurementsVoltage = [
    'voltage_0',
    'voltage_1',
    'voltage_2',
    'voltage_3',
    'voltage_4',
]

const units = {
    angle: '°',
    barometric_pressure: 'hPa',
    battery: '%',
    battery_voltage: 'V',
    co2: 'ppm',
    distance: 'm',
    distance_to_service: 'km',
    ev_battery_health: '%',
    ev_battery_percentage: '%',
    external_voltage: 'V',
    fill_level: '%',
    fuel_level: '%',
    fuel_rate: 'l/h',
    fuel_total: 'l',
    humidity: '%',
    humidity_absolute: 'g/m³',
    level: 'm',
    lightning_average_distance: 'km',
    mass: 'kg',
    maximum_wind_speed: 'm/s',
    moisture: '%',
    odometer: 'km',
    percentage: '%',
    power: 'W',
    precipitation: 'mm/h',
    running_time: 'h',
    solar_radiation: 'W/m²',
    speed: 'km/h',
    t1: '°C',
    t2: '°C',
    t3: '°C',
    t4: '°C',
    t5: '°C',
    t6: '°C',
    tachograph_odometer: 'km',
    temperature: '°C',
    vapor_pressure: 'kPa',
    voc: 'ppb',
    voltage: 'V',
    voltage_0: 'V',
    voltage_1: 'V',
    voltage_2: 'V',
    voltage_3: 'V',
    voltage_4: 'V',
    volume: 'm³',
    water_level: 'mm',
    wind_direction: '°',
    wind_speed: 'm/s',
}

const unitsByAssetType = {
    'fency-boy': {
        voltage_0: 'kV',
    },
    silo: {
        mass: 't',
    },
}

const converters = {
    accel_y: () => 1,
    d1: value => +value,
    d2: value => +value,
    d3: value => +value,
    d4: value => +value,
    d5: value => +value,
    d6: value => +value,
    default: value => value.toFixed(2),
    distance_to_service: value => (value / 1000).toFixed(2),
    energy_0: value => (value / 1000).toFixed(2),
    fence_voltage: value => (value / 1000).toFixed(2),
    fill_level: value => parseInt(value * 100).toFixed(2),
    lightning_average_distance: value => (value / 1000).toFixed(2),
    odometer: value => (value / 1000).toFixed(2),
    silo_mass: value => (value / 1000).toFixed(2),
    speed: value => Math.round(value * 3.6).toFixed(2),
    tachograph_odometer: value => (value / 1000).toFixed(2),
    vapor_pressure: value => (value / 1000).toFixed(2),
}

const calculateAbsoluteHumidity = (humidity, temperature) => {
    if (typeof humidity !== 'number' || typeof temperature !== 'number') {
        return null
    }
    return (
        (Math.pow(
            10,
            25.552 -
                4.9283 * Math.log10(temperature + 273.15) -
                2937.4 / (temperature + 273.15)
        ) *
            humidity) /
        100 /
        ((461.53 * (temperature + 273.15)) / 1000)
    ).toFixed(3)
}

const convertToBinLevel = value => {
    const height = 1.2 // meters

    value = (height - value) / height

    if (value < 0) {
        value = 0
    } else if (value > 1) {
        value = 1
    }

    return Math.round(value * 100)
}

const convertToParkingAvailability = value => {
    return value > 1.6
}

const convertToSbbBinLevel = value => {
    if (value < 0.05) {
        value = 0
    } else if (value > 0.72) {
        value = 0.67
    } else {
        value -= 0.05
    }

    return 100 - Math.floor((value / 0.67) * 100)
}

export default {
    calculateAbsoluteHumidity,
    convertToBinLevel,
    convertToParkingAvailability,
    convertToSbbBinLevel,
    converters,
    measurements,
    measurementsAnalogChannels,
    measurementsSalt,
    measurementsStaffOnly,
    measurementsState,
    measurementsTemperature,
    measurementsVoltage,
    units,
    unitsByAssetType,
}
