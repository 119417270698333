<template>
    <div class="map-search">
        <BaseInput
            ref="input"
            v-model="query"
            :placeholder="$t('searchPlaceholder')"
            block
            @blur="isDropdownVisible = false"
            @focus="isDropdownVisible = true"
            @keydown.up.prevent="handleOptionNavigation(-1)"
            @keydown.down.prevent="handleOptionNavigation(1)"
            @keydown.enter.prevent="handleOptionSelect()"
        >
            <template #icon>
                <SearchIcon width="16" height="16" color="#acbac7" />
            </template>
        </BaseInput>

        <transition name="dropdown">
            <div
                v-if="isDropdownVisible"
                class="map-search__dropdown"
                @mousedown.prevent
            >
                <p v-if="!query" class="l-padded">
                    {{ $t('searchHint') }}
                </p>

                <p
                    v-else-if="
                        !customerFieldSuggestions.length &&
                            !filteredAssets.length &&
                            !filteredLocations.length &&
                            !places.length
                    "
                    class="l-padded"
                >
                    {{ $t('searchEmpty') }}
                </p>

                <p
                    v-if="customerFieldSuggestionsPortion.length"
                    class="l-padded"
                >
                    {{ $t('customerFields') }}
                </p>

                <a
                    v-for="(item, i) in customerFieldSuggestionsPortion"
                    :key="item"
                    :class="{ hover: i === hoverIndex }"
                    @click="handleCustomerFieldSuggestionClick(item)"
                >
                    <span>
                        <CogIcon width="16" height="16" />
                    </span>

                    {{ customerFieldLabels[item] }}
                </a>

                <a
                    v-if="
                        customerFieldSuggestions.length &&
                            customerFieldSuggestions.length >
                                customerFieldsLimit
                    "
                    class="t-small"
                    @click="customerFieldsLimit += limitStep"
                >
                    {{ $t('showMoreCustomerFields') }}
                </a>

                <p v-if="filteredAssetsPortion.length" class="l-padded">
                    {{ $t('shared.assets') }}
                    ({{ filteredAssets.length }}/{{ totalAssetsCount }})
                </p>

                <a
                    v-for="(item, i) in filteredAssetsPortion"
                    :key="'asset' + item.id"
                    :class="{
                        hover:
                            customerFieldSuggestionsPortion.length + i ===
                            hoverIndex,
                    }"
                    @click="handleAssetClick(item)"
                >
                    <span>
                        <PinIcon width="16" height="16" />
                    </span>

                    <span v-if="item.asset_details">
                        {{ item.asset_details.name }}

                        <small v-if="item.asset_details.identification">
                            {{ item.asset_details.identification }}
                        </small>
                    </span>

                    <span v-else>
                        {{ item.name }}
                    </span>
                </a>

                <a
                    v-if="
                        filteredAssets.length &&
                            filteredAssets.length > assetsLimit
                    "
                    class="t-small"
                    @click="assetsLimit += limitStep"
                >
                    {{ $t('showMore', { items: $t('shared.assets') }) }}
                </a>

                <p v-if="filteredLocationsPortion.length" class="l-padded">
                    {{ $t('locations') }}
                    ({{ filteredLocations.length }}/{{ locations.length }})
                </p>

                <a
                    v-for="(item, i) in filteredLocationsPortion"
                    :key="'location' + item.id"
                    :class="{
                        hover:
                            customerFieldSuggestionsPortion.length +
                                filteredAssetsPortion.length +
                                i ===
                            hoverIndex,
                    }"
                    @click="handleLocationClick(item)"
                >
                    <span>
                        <PinLocationIcon width="16" height="16" />
                    </span>

                    <span>{{ item.name }}</span>
                </a>

                <a
                    v-if="
                        filteredLocations.length &&
                            filteredLocations.length > locationsLimit
                    "
                    class="t-small"
                    @click="locationsLimit += limitStep"
                >
                    {{ $t('showMoreLocations') }}
                </a>

                <p v-if="placesPortion.length" class="l-padded">
                    {{ $t('places') }}
                </p>

                <a
                    v-for="(item, i) in placesPortion"
                    :key="'place' + item.id"
                    :class="{
                        hover:
                            customerFieldSuggestionsPortion.length +
                                filteredAssetsPortion.length +
                                filteredLocationsPortion.length +
                                i ===
                            hoverIndex,
                    }"
                    @click="handlePlaceClick(item)"
                >
                    <span>
                        <MapIcon width="16" height="16" />
                    </span>

                    <span>{{ item.place_name }}</span>
                </a>

                <a
                    v-if="places.length && places.length > placesLimit"
                    class="t-small"
                    @click="placesLimit += limitStep"
                >
                    {{ $t('showMorePlaces') }}
                </a>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { debounce } from 'lodash'
import moment from 'moment'

import { httpHelper } from '@/utils'
import BaseInput from '../redesigned/BaseInput'
import CogIcon from '../icons/CogIcon'
import MapIcon from '../icons/MapIcon'
import PinIcon from '../icons/PinIcon'
import PinLocationIcon from '../icons/PinLocationIcon'
import SearchIcon from '../icons/SearchIcon'

export default {
    name: 'MapSearch',
    components: {
        BaseInput,
        CogIcon,
        MapIcon,
        PinIcon,
        PinLocationIcon,
        SearchIcon,
    },
    data() {
        return {
            assetsLimit: 3,
            customerFieldsLimit: 3,
            hoverIndex: null,
            isDropdownVisible: false,
            limitStep: 3,
            locationsLimit: 3,
            places: [],
            placesLimit: 3,
            query: '',
        }
    },
    computed: {
        ...mapState('location', ['locations']),
        ...mapState('map', ['mapInstance']),
        ...mapState('sharing', ['sharedTrackers']),
        ...mapState('tracker', ['trackers']),
        ...mapGetters('tracker', ['assetTypesById', 'customerFieldLabels']),
        combinedPortion() {
            return [
                ...this.customerFieldSuggestionsPortion,
                ...this.filteredAssetsPortion,
                ...this.filteredLocationsPortion,
                ...this.placesPortion,
            ]
        },
        customerFieldSuggestions() {
            if (!this.query || this.isCustomSearch) {
                return []
            }

            const query = this.query
                .replace(/(^\[|\]$)/g, '')
                .toLocaleLowerCase()

            return Object.keys(this.customerFieldLabels).filter(
                field =>
                    this.customerFieldLabels[field]
                        .toLocaleLowerCase()
                        .includes(query) ||
                    field
                        .split(':')
                        .pop()
                        .includes(query)
            )
        },
        customerFieldSuggestionsPortion() {
            return this.customerFieldSuggestions.slice(
                0,
                this.customerFieldsLimit
            )
        },
        filteredAssets() {
            if (!this.query) {
                return []
            }

            if (this.isCustomSearch) {
                const [, , key, , query] = this.query
                    .toLowerCase()
                    .split(/(\[|\]=)/)
                return this.filterTrackersByCustomerFields(key, query)
            }

            return [
                ...this.trackers.filter(
                    asset =>
                        asset.asset_details.name
                            .toLowerCase()
                            .includes(this.query.toLowerCase()) ||
                        asset.deveui
                            .toLowerCase()
                            .includes(this.query.toLowerCase()) ||
                        asset.asset_details.identification
                            ?.toLowerCase()
                            ?.includes(this.query.toLowerCase())
                ),
                ...this.sharedTrackers.filter(tracker =>
                    tracker.name
                        .toLowerCase()
                        .includes(this.query.toLowerCase())
                ),
            ]
        },
        filteredAssetsPortion() {
            return this.filteredAssets.slice(0, this.assetsLimit)
        },
        filteredLocations() {
            if (!this.query || this.isCustomSearch) {
                return []
            }

            return this.locations.filter(location =>
                location.name.toLowerCase().includes(this.query.toLowerCase())
            )
        },
        filteredLocationsPortion() {
            return this.filteredLocations.slice(0, this.locationsLimit)
        },
        isCustomSearch() {
            return /^\[.*\]=/.test(this.query)
        },
        placesPortion() {
            return this.places.slice(0, this.placesLimit)
        },
        totalAssetsCount() {
            return this.$route.path.startsWith('/share')
                ? this.sharedTrackers.length
                : this.trackers.length
        },
    },
    watch: {
        query: debounce(async function() {
            this.assetsLimit = this.limitStep
            this.locationsLimit = this.limitStep
            this.placesLimit = this.limitStep
            this.hoverIndex = null

            if (this.query && !this.isCustomSearch) {
                const { lat, lng } = this.mapInstance.getCenter()
                const { data } = await httpHelper.get(
                    `https://api.mapbox.com/geocoding/v5/mapbox.places/${this.query}.json`,
                    {
                        params: {
                            access_token: process.env.VUE_APP_MAPBOX_TOKEN,
                            proximity: `${lng},${lat}`,
                        },
                    }
                )
                this.places = data.features
            } else {
                this.places = []
            }
        }, 250),
    },
    methods: {
        handleAssetClick(asset) {
            this.handleReset()
            if (asset.asset_details) {
                this.$router.push(`/map/assets/${asset.id}`)
            } else {
                this.$router.push(
                    `/share/${this.$route.params.token}/assets/${asset.id}`
                )
            }
        },
        handleCustomerFieldSuggestionClick(customerField) {
            this.query = `[${this.customerFieldLabels[customerField]}]=`
        },
        handleLocationClick({ id }) {
            this.handleReset()
            this.$router.push(`/map/location/${id}`)
        },
        handlePlaceClick(place) {
            this.handleReset()

            if (place.bbox) {
                const [lng1, lat1, lng2, lat2] = place.bbox
                this.mapInstance.fitBounds([
                    [lat1, lng1],
                    [lat2, lng2],
                ])
            } else {
                const [lng, lat] = place.center
                this.mapInstance.setView([lat, lng], 16)
            }
        },
        handleOptionNavigation(step) {
            if (!this.combinedPortion.length) {
                this.hoverIndex = null
                return
            }

            if (this.hoverIndex === null) {
                this.hoverIndex = 0
                return
            }

            this.hoverIndex =
                (this.hoverIndex + step + this.combinedPortion.length) %
                this.combinedPortion.length
        },
        handleOptionSelect() {
            const option = this.combinedPortion[this.hoverIndex]
            const customerFieldSuggestionsLength = this
                .customerFieldSuggestionsPortion.length
            const assetsLength = this.filteredAssetsPortion.length
            const locationsLength = this.filteredLocationsPortion.length

            if (this.hoverIndex < customerFieldSuggestionsLength) {
                this.handleCustomerFieldSuggestionClick(option)
            } else if (
                this.hoverIndex <
                customerFieldSuggestionsLength + assetsLength
            ) {
                this.handleAssetClick(option)
            } else if (
                this.hoverIndex <
                customerFieldSuggestionsLength + assetsLength + locationsLength
            ) {
                this.handleLocationClick(option)
            } else {
                this.handlePlaceClick(option)
            }
        },
        handleReset() {
            this.$refs.input.blur()
            this.hoverIndex = null
            this.query = ''
        },
        filterTrackersByCustomerFields(key, query) {
            return this.trackers.filter(tracker => {
                const schema = this.assetTypesById[
                    tracker.asset_details.asset_type
                ]?.additional_data_schema.properties
                if (!schema) {
                    return false
                }
                let format, rawValue
                if (key in schema) {
                    format = schema?.[key]?.format
                    rawValue =
                        tracker.asset_details.additional_data?.[key] ??
                        schema?.[key]?.default
                } else {
                    Object.entries(schema).forEach(
                        ([schemaKey, schemaItem]) => {
                            if (
                                schemaItem.label[
                                    this.$i18n.locale
                                ]?.toLowerCase() === key
                            ) {
                                format = schemaItem.format
                                rawValue =
                                    tracker.asset_details.additional_data?.[
                                        schemaKey
                                    ] ?? schemaItem.default
                            }
                        }
                    )
                }
                return rawValue === undefined
                    ? false
                    : (format === 'boolean'
                          ? rawValue
                              ? this.$t('shared.yes')
                              : this.$t('shared.no')
                          : format === 'date'
                          ? moment(rawValue).format('DD.MM.YYYY')
                          : rawValue
                      )
                          .toLowerCase()
                          .startsWith(query)
            })
        },
    },
}
</script>

<i18n>
{
    "en": {
        "customerFields": "Search within customer defined fields",
        "locations": "Locations",
        "places": "Jump to address",
        "searchEmpty": "Nothing found",
        "searchHint": "Start typing to search",
        "searchPlaceholder": "Search",
        "showMore": "Show more {items} ...",
        "showMoreCustomerFields": "Show more customer fields ...",
        "showMoreLocations": "Show more locations ...",
        "showMorePlaces": "Show more addresses ..."
    },
    "de": {
        "customerFields": "Suche in den benutzerdefinierte Felder",
        "locations": "Standorte",
        "places": "Adressen",
        "searchEmpty": "Nichts gefunden",
        "searchHint": "Beginnen Sie zu tippen, um zu suchen",
        "searchPlaceholder": "Suche",
        "showMore": "Mehr {items} anzeigen ...",
        "showMoreCustomerFields": "Mehr benutzerdefinierte Felder anzeigen ...",
        "showMoreLocations": "Mehr Standorte anzeigen ...",
        "showMorePlaces": "Mehr Adressen anzeigen ..."
    },
    "fr": {
        "customerFields": "Recherche dans les champs du client",
        "locations": "Emplacements",
        "places": "Adresses",
        "searchEmpty": "Rien trouvé",
        "searchHint": "Commencez à taper pour rechercher",
        "searchPlaceholder": "Rechercher",
        "showMore": "Afficher plus {items} ...",
        "showMoreCustomerFields": "Afficher d'autres champs clients ...",
        "showMoreLocations": "Afficher plus d'emplacements ...",
        "showMorePlaces": "Afficher plus d'adresses ..."
    },
    "it": {
        "customerFields": "Ricerca nei campi del cliente",
        "locations": "Locazione",
        "places": "Indirizzi",
        "searchEmpty": "Niente di trovato",
        "searchHint": "Iniziare a digitare per cercare",
        "searchPlaceholder": "Cerca",
        "showMore": "Mostra altri {items} ...",
        "showMoreCustomerFields": "Mostra altri campi cliente ...",
        "showMoreLocations": "Mostra altri luoghi ...",
        "showMorePlaces": "Mostra altri indirizzi ..."
    }
}
</i18n>

<style lang="scss" scoped>
.map-search {
    position: relative;
    display: flex;
    width: 100%;

    .base-input {
        width: 100%;
    }

    &__dropdown {
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        margin: 6px 0 12px;
        padding: 8px 0;
        width: 100%;
        max-height: 60vh;
        background-color: #fff;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        font-size: 14px;
        text-align: left;
        overflow: hidden auto;
        z-index: 1000;

        a {
            display: flex;
            padding: 12px 20px;
            transition: background-color 0.1s;
            cursor: pointer;

            &:hover {
                background-color: $color-gray-lighter-new;
            }

            &.hover {
                background-color: $color-gray-light-new;
            }

            svg {
                margin-right: 8px;
                margin-bottom: -3px;
            }

            small {
                color: $color-gray-dark-new;
                font-size: 1em;
            }
        }

        p {
            text-align: center;
            color: $color-gray-dark-new;
        }
    }
}

.dropdown {
    &-enter-active,
    &-leave-active {
        transition: all 0.1s ease-out;
    }

    &-enter,
    &-leave-to {
        transform: translateY(-8px);
        opacity: 0;
    }
}
</style>
