<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <circle class="a" cx="11.5" cy="7.5" r="4.5" />
        <path class="a" d="M11.125,6a.375.375,0,0,1,.375.375" />
        <path class="a" d="M10.75,6.375A.375.375,0,0,1,11.125,6" />
        <path class="a" d="M11.125,6.75a.375.375,0,0,1-.375-.375" />
        <path class="a" d="M11.5,6.375a.375.375,0,0,1-.375.375" />
        <line class="a" x1="11.5" y1="12" x2="11.5" y2="21" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'PinIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
