export default {
    computed: {
        accountSpecificLogo() {
            return this.$store.state.auth.userInfo?.account_details.logo
        },
        isWhitelabled() {
            return (
                !!this.sharingLogo ||
                !!this.accountSpecificLogo ||
                (!!process.env.VUE_APP_LOGO_ASSET_PATH &&
                    process.env.VUE_APP_LOGO_ASSET_PATH !== 'logo.svg')
            )
        },
        sharingLogo() {
            return this.$store.state.sharing.sharingConfig?.logo
        },
        toolbarFontColor() {
            return this.$store.state.auth.userInfo?.account_details
                .toolbar_font_color
        },
        whitelabelColor() {
            return (
                this.$store.state.sharing.sharingConfig?.toolbar_color ||
                this.$store.state.auth.userInfo?.account_details.toolbar_color
            )
        },
        whitelabelLogo() {
            return (
                this.sharingLogo ||
                this.accountSpecificLogo ||
                require(`@/assets/${process.env.VUE_APP_LOGO_ASSET_PATH}`)
            )
        },
    },
}
