<template>
    <Icon
        color="none"
        fill-color="none"
        :width="width"
        :height="height"
        :viewbox-width="80"
        :viewbox-height="20"
        @click="$emit('click')"
    >
        <g :class="{ light }">
            <path
                d="M7.37381 11.7778L6.04683 7.99286L4.71984 11.7778H7.37381ZM8.15794 14.0246H3.93571L3.25714 16H0.105556L4.43333 4.63016H7.66032L11.9881 16H8.83651L8.15794 14.0246Z"
            />

            <template v-if="animated">
                <g class="x-group">
                    <path
                        d="M24.2856 4.44392L18.5742 10.1582L12.8571 4.44392 M12.8572 15.8725L18.5687 10.1582L24.2857 15.8725"
                    />

                    <path
                        class="x-clip accent"
                        d="M24.2856 4.44392L18.5742 10.1582L12.8571 4.44392 M12.8572 15.8725L18.5687 10.1582L24.2857 15.8725"
                    />
                </g>
            </template>

            <template v-else>
                <path
                    class="accent"
                    d="M24.2856 4.44392L18.5742 10.1582L12.8571 4.44392"
                />

                <path d="M12.8572 15.8725L18.5687 10.1582L24.2857 15.8725" />
            </template>

            <path
                d="M31.0595 7.13333V16H28.104V7.13333H25.6762V4.63016H33.4873V7.13333H31.0595Z"
            />

            <path
                d="M38.2738 9.7119H38.8317C39.4148 9.7119 39.8621 9.59127 40.1738 9.35C40.4854 9.10873 40.6412 8.7619 40.6412 8.30952C40.6412 7.85714 40.4854 7.51032 40.1738 7.26905C39.8621 7.02778 39.4148 6.90714 38.8317 6.90714H38.2738V9.7119ZM44.773 16H41.0936L38.2738 11.627V16H35.3182V4.63016H39.9174C40.5508 4.63016 41.1037 4.72566 41.5762 4.91667C42.0486 5.09762 42.4357 5.34894 42.7373 5.67063C43.0489 5.99233 43.2801 6.36428 43.4309 6.78651C43.5918 7.20873 43.6722 7.66111 43.6722 8.14365C43.6722 9.0082 43.4611 9.7119 43.0389 10.2548C42.6267 10.7876 42.0135 11.1495 41.1992 11.3405L44.773 16Z"
            />

            <path
                d="M53.088 11.7778L51.7611 7.99286L50.4341 11.7778H53.088ZM53.8722 14.0246H49.6499L48.9714 16H45.8198L50.1476 4.63016H53.3746L57.7023 16H54.5507L53.8722 14.0246Z"
            />

            <path
                d="M66.6809 8.43016C65.9973 7.59577 65.1528 7.17857 64.1475 7.17857C63.7052 7.17857 63.293 7.25899 62.911 7.41984C62.5391 7.58069 62.2174 7.80185 61.946 8.08333C61.6745 8.35476 61.4584 8.68148 61.2975 9.06349C61.1468 9.4455 61.0714 9.85767 61.0714 10.3C61.0714 10.7524 61.1468 11.1696 61.2975 11.5516C61.4584 11.9336 61.6745 12.2653 61.946 12.5468C62.2274 12.8283 62.5542 13.0495 62.9261 13.2103C63.2981 13.3712 63.7002 13.4516 64.1325 13.4516C65.0774 13.4516 65.9269 13.0495 66.6809 12.2452V15.7437L66.3793 15.8492C65.9269 16.0101 65.5047 16.1257 65.1126 16.196C64.7206 16.2765 64.3335 16.3167 63.9515 16.3167C63.1674 16.3167 62.4134 16.1709 61.6896 15.8794C60.9759 15.5778 60.3425 15.1606 59.7896 14.6278C59.2468 14.0849 58.8095 13.4466 58.4777 12.7127C58.146 11.9688 57.9801 11.1595 57.9801 10.2849C57.9801 9.41032 58.1409 8.61111 58.4626 7.8873C58.7944 7.15344 59.2317 6.52513 59.7745 6.00238C60.3274 5.46958 60.9658 5.05741 61.6896 4.76587C62.4134 4.46428 63.1724 4.31349 63.9666 4.31349C64.419 4.31349 64.8613 4.36376 65.2936 4.46428C65.7359 4.55476 66.1983 4.70053 66.6809 4.90159V8.43016Z"
            />

            <path
                d="M71.7658 9.31984L75.3698 4.63016H79.019L74.5103 10.0135L79.4412 16H75.6111L71.7658 11.069V16H68.8103V4.63016H71.7658V9.31984Z"
            />
        </g>
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'LogoIcon',
    components: {
        Icon,
    },
    props: {
        animated: {
            type: Boolean,
            default: false,
        },
        width: {
            type: [Number, String],
            default: 80,
        },
        height: {
            type: [Number, String],
            default: 20,
        },
        light: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss" scoped>
g {
    fill: lighten($color-primary, 7.5%);

    &.light {
        fill: $color-primary-contrast;
    }
}

.accent {
    fill: $color-accent;
}

.x-group {
    transform-box: fill-box;
    transform-origin: center;
    animation: x-group-animation 2s infinite;
}

@keyframes x-group-animation {
    75% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-180deg);
    }
}

.x-clip {
    transform-box: fill-box;
    transform-origin: center;
    animation: x-clip-animation 2s infinite;
}

@keyframes x-clip-animation {
    0% {
        clip-path: inset(0% 0% 50% 0%);
    }
    25% {
        clip-path: inset(0% 0% 50% 0%);
    }
    50% {
        clip-path: inset(50% 0% 0% 0%);
    }
    100% {
        clip-path: inset(50% 0% 0% 0%);
    }
}
</style>
