const state = {
    customColumns: null,
}

const getters = {}

const actions = {}

const mutations = {
    setCustomColumns(state, data) {
        state.customColumns = data
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
