const state = {
    isDrawingLocationPolygon: false,
    isResettingLocationPolygon: false,
    locationPolygon: [],
    locationPolygonInitial: [],
}

const getters = {}

const actions = {}

const mutations = {
    setDrawingLocationPolygon(state, data) {
        state.isDrawingLocationPolygon = data
    },
    setLocationPolygon(state, data) {
        state.locationPolygon = data
    },
    setLocationPolygonInitial(state, data) {
        state.locationPolygonInitial = data
    },
    setResettingLocationPolygon(state, data) {
        state.isResettingLocationPolygon = data
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
