<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <line class="a" x1="12.751" y1="15.75" x2="18.751" y2="15.75" />

        <line class="a" x1="5.251" y1="15.75" x2="8.251" y2="15.75" />

        <path
            class="b"
            d="M12.751,15.75A2.25,2.25,0,1,1,10.5,13.5,2.25,2.25,0,0,1,12.751,15.75Z"
        />

        <line class="a" x1="14.251" y1="8.25" x2="5.251" y2="8.25" />

        <path
            class="b"
            d="M18.751,8.25A2.25,2.25,0,1,0,16.5,10.5,2.251,2.251,0,0,0,18.751,8.25Z"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'SettingsSliderIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a,
.b {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}

.b {
    fill-rule: evenodd;
}
</style>
