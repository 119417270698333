<template>
    <transition :name="transition ? 'logo-fade' : null">
        <img
            v-if="isWhitelabled"
            :src="whitelabelLogo"
            :height="height"
            :alt="alt"
        />
        <LogoIcon
            v-else
            :width="width || height * 4"
            :height="height"
            :light="light"
        />
    </transition>
</template>

<script>
import LogoIcon from '@/components/icons/LogoIcon'
import Whitelabling from '@/mixins/Whitelabling'

export default {
    name: 'WhitelabledLogo',
    components: {
        LogoIcon,
    },
    mixins: [Whitelabling],
    props: {
        alt: {
            type: String,
            default: 'Logo',
        },
        height: {
            type: [Number, String],
            default: 40,
        },
        light: {
            type: Boolean,
            default: false,
        },
        transition: {
            type: Boolean,
            default: false,
        },
        width: {
            type: [Number, String],
            default: null,
        },
    },
}
</script>

<style lang="scss" scoped>
img {
    width: auto;
}

.logo-fade {
    &-enter-active,
    &-leave-active {
        transition: opacity 0.3s;
    }

    &-enter,
    &-leave-to {
        position: absolute;
        opacity: 0;
    }
}
</style>
