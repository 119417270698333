import moment from 'moment'
import jwtDecode from 'jwt-decode'

import { userService } from '@/service/user.service'

const isTokenValid = token => {
    return token && moment.unix(jwtDecode(token).exp).isAfter()
}

const state = {
    jwt: JSON.parse(localStorage.getItem('JWT')),
    jwtOverwritten: JSON.parse(localStorage.getItem('JWT-overwritten')),
    userInfo: JSON.parse(localStorage.getItem('userInfo')),
}

const getters = {
    batteryThreshold(state) {
        return state.userInfo?.alertsettings?.battery_alert_threshold || 20
    },
    getCurrentUser(state) {
        return state.jwt?.access ? jwtDecode(state.jwt.access) : null
    },
    getCurrentUserId(state, getters) {
        return getters.getCurrentUser?.user_id
    },
    hasActivityAccess(state) {
        return state.userInfo?.platform === 'alplora'
    },
    hasAdvancedRuleAccess(state, getters) {
        return !!getters.getCurrentUser?.advanced_rule_access
    },
    hasConstructionProjectAccess(state, getters) {
        return !!getters.getCurrentUser?.construction_project_access
    },
    hasDashboardAccess(state, getters) {
        return !!getters.getCurrentUser?.dashboard_access
    },
    hasDebugAccess(state, getters) {
        return !!getters.getCurrentUser?.debug_access
    },
    hasLocationHistoryAccess(state, getters) {
        return !!getters.getCurrentUser?.location_history_access
    },
    hasMaintenanceAccess(state, getters) {
        return !!getters.getCurrentUser?.maintenance_access
    },
    hasNavigationAccess(state, getters) {
        if (
            !process.env.VUE_APP_NAVIGATION_USERS ||
            !getters.getCurrentUser?.username
        ) {
            return false
        }

        const navigationUsers = JSON.parse(process.env.VUE_APP_NAVIGATION_USERS)
        return navigationUsers.includes(getters.getCurrentUser.username)
    },
    hasReportingAccess(state, getters) {
        return !!getters.getCurrentUser?.reporting_access
    },
    hasRfidAccess(state, getters) {
        return !!getters.getCurrentUser?.rfid_access
    },
    hasRulesAccess(state, getters) {
        return !!getters.getCurrentUser?.rule_access
    },
    hasRunningTimeAccess(state, getters) {
        return !!getters.getCurrentUser?.running_time_access
    },
    hasSharingAccess(state, getters) {
        return !!getters.getCurrentUser?.sharing_access
    },
    hasTachographAccess(state, getters) {
        return !!getters.getCurrentUser?.tachograph_access
    },
    hasUserManagementAccess(state, getters) {
        return !!getters.getCurrentUser?.user_management_access
    },
    hasWebhookAccess(state, getters) {
        return !!getters.getCurrentUser?.webhook_access
    },
    isAdmin(state) {
        const token = state.jwtOverwritten?.access || state.jwt?.access
        return token && jwtDecode(token).is_account_admin
    },
    isAdminOrStaff(state, getters) {
        return (
            getters.getCurrentUser &&
            (getters.getCurrentUser.is_account_admin ||
                getters.getCurrentUser.is_staff)
        )
    },
    isDemoUser(state, getters) {
        return (
            getters.getCurrentUser?.username ===
            process.env.VUE_APP_DEMO_USERNAME
        )
    },
    isSimulationMode(state) {
        return !!state.jwtOverwritten
    },
    isStaff(state) {
        const token = state.jwtOverwritten?.access || state.jwt?.access
        return token && jwtDecode(token).is_staff
    },
    isSuperuser(state) {
        const token = state.jwtOverwritten?.access || state.jwt?.access
        return token && jwtDecode(token).is_superuser
    },
}

const actions = {
    async loadUserInfo({ commit, getters }) {
        const userInfo = await userService.fetchUserInfo(
            getters.getCurrentUserId
        )

        localStorage.setItem('userInfo', JSON.stringify(userInfo))
        commit('setUserInfo', userInfo)
    },
    async login({ commit }, { username, password }) {
        commit('resetAuth')
        const jwt = await userService.login(username, password)
        commit('setJwt', jwt)
    },
    logout({ commit }) {
        userService.logout()
        commit('resetAuth')
    },
    async refreshTokenIfNecessary({ state, commit, dispatch }) {
        if (isTokenValid(state.jwt?.access)) {
            return true
        } else if (isTokenValid(state.jwt?.refresh)) {
            try {
                const jwt = await userService.refreshAccessToken(
                    state.jwt.refresh
                )
                commit('setJwt', jwt)
                return true
            } catch {
                dispatch('logout')
            }
        } else {
            dispatch('logout')
        }
    },
    async simulateUser({ commit, state }, id) {
        if (!state.jwtOverwritten) {
            const jwtOverwritten = localStorage.getItem('JWT')
            const userInfoOverwritten = localStorage.getItem('userInfo')

            localStorage.setItem('JWT-overwritten', jwtOverwritten)
            localStorage.setItem('userInfo-overwritten', userInfoOverwritten)

            commit('setJwtOverwritten', JSON.parse(jwtOverwritten))
        }

        const { access, refresh } = await userService.fetchUserToken(id)
        localStorage.setItem('JWT', JSON.stringify({ access, refresh }))
        commit('setJwt', { access, refresh })

        const userInfo = await userService.fetchUserInfo(id)
        localStorage.setItem('userInfo', JSON.stringify(userInfo))
        commit('setUserInfo', userInfo)
    },
    stopSimulatingUser({ commit }) {
        commit('setJwtOverwritten', null)

        const token = localStorage.getItem('JWT-overwritten')
        const userInfo = localStorage.getItem('userInfo-overwritten')

        if (!token || !userInfo) {
            return
        }

        localStorage.setItem('JWT', token)
        localStorage.setItem('userInfo', userInfo)

        localStorage.removeItem('JWT-overwritten')
        localStorage.removeItem('userInfo-overwritten')

        commit('setJwt', JSON.parse(token))
        commit('setUserInfo', JSON.parse(userInfo))
    },
}

const mutations = {
    resetAuth(state) {
        state.jwt = null
        state.jwtOverwritten = null
        state.userInfo = null
    },
    setJwt(state, jwt) {
        state.jwt = jwt
    },
    setJwtOverwritten(state, data) {
        state.jwtOverwritten = data
    },
    setUserInfo(state, data) {
        state.userInfo = data
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
