import axios from 'axios'

import { httpHelper } from '../utils'

const foreignTokenUrl = process.env.VUE_APP_API_URL.replace(
    /v\d+\/?$/,
    'foreign-token/'
)
export const userService = {
    login,
    logout,
    refreshAccessToken,
    fetchUserInfo,
    fetchUserToken,
    fetchUserAlertSettings,
}

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    }

    return axios
        .post(
            process.env.VUE_APP_JWT_API_URL,
            JSON.stringify({ username, password }),
            requestOptions
        )
        .then(response => {
            const data = response.data
            // login successful if there's a jwt token in the response
            if (data.access) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('JWT', JSON.stringify(data))
            }

            return data
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    logout()
                }
                return Promise.reject(error)
            }
        })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('JWT')
    localStorage.removeItem('JWT-overwritten')
    localStorage.removeItem('userInfo')
    localStorage.removeItem('userInfo-overwritten')
}

function refreshAccessToken(refreshToken) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    }

    return axios
        .post(
            process.env.VUE_APP_JWT_API_URL + 'refresh/',
            JSON.stringify({ refresh: refreshToken }),
            requestOptions
        )
        .then(response => {
            const data = response.data
            // login successful if there's a jwt token in the response
            if (data.access) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('JWT', JSON.stringify(data))
            }

            return data
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    logout()
                }
                return Promise.reject()
            }
        })
}

async function fetchUserInfo(id) {
    const httpConfig = await httpHelper.getOverwrittenConfig()

    const { data } = await httpHelper.get(`/users/${id}/`, httpConfig)
    data.alertsettings = await fetchUserAlertSettings(id)

    return data
}

async function fetchUserAlertSettings(id) {
    const httpConfig = await httpHelper.getOverwrittenConfig()

    const { data } = await httpHelper.get(
        `/users/${id}/alertsettings/`,
        httpConfig
    )

    return data
}

async function fetchUserToken(id) {
    const httpConfig = await httpHelper.getOverwrittenConfig()

    const { data } = await httpHelper.post(
        foreignTokenUrl,
        { foreign_user: id },
        httpConfig
    )

    return data
}
