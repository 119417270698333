const state = {
    maintenancePolicies: [],
}

const getters = {
    maintenancePolicyMap(state) {
        return state.maintenancePolicies.reduce(
            (acc, cur) => ({ ...acc, [cur.id]: cur }),
            {}
        )
    },
}

const actions = {}

const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
