<template>
    <header class="l-padded l-inline l-spread l-center-v base-header">
        <RemoveIcon
            v-if="$listeners.close"
            width="24"
            height="24"
            class="clickable"
            @click="$emit('close')"
        />

        <router-link v-else to=".." append>
            <RemoveIcon width="24" height="24" />
        </router-link>

        <div class="l-padded-x base-header__title">
            {{ title }}
        </div>
    </header>
</template>

<script>
import RemoveIcon from '../icons/RemoveIcon'

export default {
    name: 'BaseHeader',
    components: {
        RemoveIcon,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.base-header {
    position: sticky;
    top: 0;
    background-color: #fff;
    border-bottom: 1px solid $color-gray-light-new;
    z-index: 1;

    & > * {
        height: 24px;
    }

    &__title {
        margin-right: 24px;
        width: 100%;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        color: $color-text-new;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>
