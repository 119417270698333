import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import VueModal from 'vue-js-modal'
import VueNotifications from 'vue-notification'
import VueOffline from 'vue-offline'
import Vuelidate from 'vuelidate'
import { VTooltip } from 'v-tooltip'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

import store from './store'
import router from './router'
import { messages } from './i18n'
import { languageHelper } from './utils'
import App from './App.vue'

import './registerServiceWorker'

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production'
Vue.config.performance = process.env.NODE_ENV !== 'production'

// Integrate Freshworks widget
if (process.env.VUE_APP_FRESHWORKS) {
    const id = 201000000876

    window.fwSettings = { widget_id: id }
    if (typeof window.FreshworksWidget !== 'function') {
        const widget = function() {
            widget.q.push(arguments)
        }
        widget.q = []
        window.FreshworksWidget = widget
    }
    window.FreshworksWidget('identify', 'ticketForm', {
        custom_fields: {
            cf_environment: process.env.VUE_APP_ENVIRONMENT_NAME,
        },
    })
    window.FreshworksWidget('hide', 'ticketForm', [
        'product_id',
        'custom_fields.cf_environment',
    ])

    const widgetScript = document.createElement('script')
    widgetScript.src = `https://euc-widget.freshworks.com/widgets/${id}.js`
    document.body.appendChild(widgetScript)
}

// Adding Sentry for Error logging
if (
    process.env.NODE_ENV !== 'development' &&
    process.env.VUE_APP_ENVIRONMENT_NAME !== 'feature review'
) {
    Sentry.init({
        dsn: 'https://e6967b80198947e5a9db78fd651c4479@sentry.io/1481557',
        integrations: [
            new Integrations.Vue({
                Vue,
                attachProps: true,
            }),
        ],
        release: 'ax-track@' + process.env.VUE_APP_VERSION,
        environment: process.env.VUE_APP_ENVIRONMENT_NAME,
    })
    const currentUserId = store.getters['auth/getCurrentUserId']
    if (currentUserId) {
        Sentry.configureScope(scope => {
            scope.setUser({ id: currentUserId })
        })
    }
}

// Add Vue plugins
Vue.use(VueI18n)
Vue.use(VueModal, {
    dialog: true,
    dynamic: true,
    injectModalsContainer: true,
})
Vue.use(VueNotifications, {
    componentName: 'VNotifications',
})
Vue.use(VueOffline)
Vue.use(Vuelidate)
Vue.directive('tooltip', VTooltip, {
    defaultHtml: false,
})

Vue.directive('focus', {
    inserted: function(el) {
        el.focus()
    },
})

// Initialize internationalization
const i18n = new VueI18n({
    locale: languageHelper.getUsersLanguage(), // set locale
    silentFallbackWarn: true,
    messages, // set locale messages
})

// Create Vue instance
new Vue({
    el: '#app',
    router,
    store,
    axios,
    i18n,
    render: h => h(App),
})
