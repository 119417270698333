import { merge } from 'lodash'

import messages from '../i18n/translation'
import alploraMessages from '../i18n/translation.alplora'

const updateMessages = (i18n, platform) => {
    if (platform === 'alplora') {
        const locale = i18n.locale
        const merged = merge(messages[locale], alploraMessages[locale])
        i18n.setLocaleMessage(locale, merged)
    }
}

export default {
    beforeMount() {
        if (this.$store.state.auth.userInfo?.platform) {
            updateMessages(
                this.$root.$i18n,
                this.$store.state.auth.userInfo.platform
            )
        }
    },
    watch: {
        ['$store.state.auth.userInfo'](userInfo) {
            if (userInfo?.platform) {
                updateMessages(this.$root.$i18n, userInfo.platform)
            }
        },
    },
}
