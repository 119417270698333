import Vue from 'vue'
import pwaInstallHandler from 'pwa-install-handler'

const ChangelogModal = () =>
    import('@/components/redesigned/ChangelogModal.vue')

let canInstall

export default {
    canInstall() {
        return new Promise(resolve =>
            canInstall !== undefined
                ? resolve(canInstall)
                : pwaInstallHandler.addListener(value => {
                      canInstall = value
                      resolve(canInstall)
                  })
        )
    },
    install: pwaInstallHandler.install,
    showChangelog(showActions = false) {
        Vue.prototype.$modal.show(ChangelogModal, {
            showActions,
        })
    },
}
