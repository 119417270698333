<template>
    <div class="support-modal">
        <BaseHeader :title="$t('title')" @close="$emit('close')" />

        <div class="l-padded support-modal__content">
            <p v-if="info.time">{{ info.time }}</p>

            <p v-if="info.email">
                {{ $t('email') }}:
                <a :href="'mailto:' + info.email" class="t-underline">
                    {{ info.email }}
                </a>
            </p>
        </div>

        <footer
            v-if="hasFreshworksWidget"
            class="l-padded l-inline l-justify-end support-modal__actions"
        >
            <BaseButton size="small" @click="handleContact">
                {{ $t('contactUs') }}
            </BaseButton>
        </footer>
    </div>
</template>

<script>
import { mapState } from 'vuex'

import BaseButton from './BaseButton'
import BaseHeader from './BaseHeader'

export default {
    name: 'SupportModal',
    components: {
        BaseButton,
        BaseHeader,
    },
    data() {
        return {
            info: JSON.parse(process.env.VUE_APP_SUPPORT_INFO),
            hasFreshworksWidget: !!process.env.VUE_APP_FRESHWORKS,
        }
    },
    computed: {
        ...mapState('auth', ['userInfo']),
    },
    beforeDestroy() {
        window.FreshworksWidget('close')
    },
    methods: {
        handleContact() {
            window.FreshworksWidget('open')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "contactUs": "Contact us",
        "email": "Email",
        "title": "Support"
    },
    "de": {
        "contactUs": "Kontakt",
        "email": "Email",
        "title": "Support"
    },
    "fr": {
        "contactUs": "Contactez nous",
        "email": "E-mail",
        "title": "Assistance"
    },
    "it": {
        "contactUs": "Contattateci",
        "email": "E-mail",
        "title": "Supporto"
    }
}
</i18n>

<style lang="scss">
.support-modal {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    &__content {
        flex: 1 0 auto;
        margin-bottom: 68px;
    }

    &__actions {
        position: absolute;
        bottom: 0;
        width: 100%;
        border-top: 1px solid $color-gray-light-new;
        background-color: #fff;
    }
}
</style>
