<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <path class="a" d="M3.046,10.061a8.931,8.931,0,1,1,17.846,0" />

        <path class="a" d="M19.589,18.273a8.917,8.917,0,0,1-5.832,3.632" />

        <path
            class="a"
            d="M5.775,15.85a2.266,2.266,0,0,1-.412,1.678,2.227,2.227,0,0,1-4-1L.776,12.649a2.261,2.261,0,0,1,.412-1.678,2.226,2.226,0,0,1,4,1Z"
        />

        <path
            class="a"
            d="M18.225,15.85a2.266,2.266,0,0,0,.412,1.678,2.227,2.227,0,0,0,4-1l.586-3.879a2.261,2.261,0,0,0-.412-1.678,2.226,2.226,0,0,0-4,1Z"
        />

        <ellipse class="a" cx="11.969" cy="21.386" rx="1.86" ry="1.875" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'HeadphonesIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
