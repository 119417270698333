import { httpHelper } from '@/utils'

const state = {
    isLoadingTrips: false,
    selectedTripId: null,
    trips: [],
}

const getters = {
    selectedTrip(state) {
        return state.selectedTripId
            ? state.trips.find(trip => trip.id === state.selectedTripId)
            : null
    },
}

const actions = {
    async loadTrips({ commit }, { assetId, fromDate, toDate }) {
        commit('setLoadingTrips', true)
        commit('setTrips', [])

        let url =
            'measurementseries-full/?' +
            `asset=${assetId}` +
            `&start=${encodeURIComponent(fromDate)}` +
            `&end=${encodeURIComponent(toDate)}` +
            `&limit=${process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST}`
        const trips = []
        while (url) {
            const { data } = await httpHelper.get(url)
            trips.push(...data.results)
            url = data.next
        }

        // We need to het the measurement in ascending order (on timestamp), so that
        // on the trip history layer we can look forward and determine the travel direction of the asset.
        for (const trip of trips) {
            if (trip.measurements) {
                trip.measurements.sort(
                    (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
                )

                const lastWithOdometer = trip.measurements.findLast(
                    ({ sensor_data }) =>
                        sensor_data.tachograph_odometer || sensor_data.odometer
                )
                trip.last_odometer_value =
                    lastWithOdometer?.sensor_data.tachograph_odometer ||
                    lastWithOdometer?.sensor_data.odometer

                const lastWithTripDistance = trip.measurements.findLast(
                    ({ trip_distance }) => trip_distance > 0
                )
                trip.trip_distance = lastWithTripDistance?.trip_distance
            }
        }

        commit('setTrips', Object.freeze(trips))
        commit('setLoadingTrips', false)
    },
}

const mutations = {
    setLoadingTrips(state, data) {
        state.isLoadingTrips = data
    },
    setSelectedTripId(state, data) {
        state.selectedTripId = data
    },
    setTrips(state, data) {
        state.trips = data
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
