import store from '@/store'
import translations from '@/i18n/translation'

const defaultLanguage = 'de'

function getUsersLanguage() {
    if (store.state.auth.userInfo?.language) {
        return store.state.auth.userInfo.language
    }

    if (navigator.language) {
        const langs = (navigator.languages || [navigator.language])
            .map(lang => lang.split('-')[0])
            .filter(lang => Object.keys(translations).includes(lang))

        if (langs.length) {
            return langs[0]
        }
    }

    return defaultLanguage
}

export default {
    defaultLanguage,
    getUsersLanguage,
}
