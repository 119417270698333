<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <path class="a" d="M18.707,6.246A9,9,0,0,0,3,12.247V13" />

        <path class="a" d="M5.293,18.249a9,9,0,0,0,15.707-6V11.5" />

        <polyline class="a" points="18.75 13.747 21 11.497 23.25 13.747" />

        <polyline class="a" points="5.25 10.747 3 12.997 0.75 10.747" />

        <rect
            class="a"
            x="8.25"
            y="9.997"
            width="7.5"
            height="6"
            rx="1.5"
            ry="1.5"
        />

        <path
            class="a"
            d="M12,12.622a.375.375,0,1,0,.375.375A.375.375,0,0,0,12,12.622h0"
        />

        <path class="a" d="M9.75,10v-.75a2.25,2.25,0,0,1,4.5,0V10" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'ChangePasswordIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
