<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <path
            class="a"
            d="M.75,6.26a1.5,1.5,0,0,1,.943-1.393L7.074,2.715a3,3,0,0,1,2.333.044l4.487,1.994a3,3,0,0,0,2.244.078l5.1-1.855a1.5,1.5,0,0,1,2.013,1.41V17.694a1.5,1.5,0,0,1-.987,1.409l-6.125,2.228a3,3,0,0,1-2.244-.078L9.407,19.259a3,3,0,0,0-2.333-.044L2.807,20.922A1.5,1.5,0,0,1,.75,19.529Z"
        />

        <path class="a" d="M12.375,11.244a.375.375,0,0,1,.375.375" />

        <path class="a" d="M12,11.619a.375.375,0,0,1,.375-.375" />

        <path class="a" d="M12.375,11.994A.375.375,0,0,1,12,11.619" />

        <path class="a" d="M12.75,11.619a.375.375,0,0,1-.375.375" />

        <path class="a" d="M9.375,13.75a.375.375,0,0,1,.375.375" />

        <path class="a" d="M9,14.125a.375.375,0,0,1,.375-.375" />

        <path class="a" d="M9.375,14.5A.375.375,0,0,1,9,14.125" />

        <path class="a" d="M9.75,14.125a.375.375,0,0,1-.375.375" />

        <path class="a" d="M6.375,11.5a.375.375,0,0,1,.375.375" />

        <path class="a" d="M6,11.875a.375.375,0,0,1,.375-.375" />

        <path class="a" d="M6.375,12.25A.375.375,0,0,1,6,11.875" />

        <path class="a" d="M6.75,11.875a.375.375,0,0,1-.375.375" />

        <path class="a" d="M4.125,14.5a.375.375,0,0,1,.375.375" />

        <path class="a" d="M3.75,14.875a.375.375,0,0,1,.375-.375" />

        <path class="a" d="M4.125,15.25a.375.375,0,0,1-.375-.375" />

        <path class="a" d="M4.5,14.875a.375.375,0,0,1-.375.375" />

        <line class="a" x1="15.75" y1="8.244" x2="20.25" y2="12.744" />

        <line class="a" x1="15.75" y1="12.744" x2="20.25" y2="8.244" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'HeatmapIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
