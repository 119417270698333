const state = {
    activeLocationOnMap: null,
    locations: [],
}

const getters = {
    locationNames(state) {
        return state.locations.reduce((acc, location) => {
            acc[location.id] = location.name
            return acc
        }, {})
    },
    locationsSortedByName(state) {
        return [...state.locations].sort((a, b) => a.name.localeCompare(b.name))
    },
}

const actions = {}

const mutations = {
    addLocation(state, data) {
        state.locations.push(data)
    },
    setActiveLocationOnMap(state, data) {
        state.activeLocationOnMap = data
    },
    updateLocation(state, data) {
        const location = state.locations.find(({ id }) => id === data.id)
        Object.keys(data).forEach(key => {
            location[key] = data[key]
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
