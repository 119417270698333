import { render, staticRenderFns } from "./CogIcon.vue?vue&type=template&id=bd71df3c&scoped=true&"
import script from "./CogIcon.vue?vue&type=script&lang=js&"
export * from "./CogIcon.vue?vue&type=script&lang=js&"
import style0 from "./CogIcon.vue?vue&type=style&index=0&id=bd71df3c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd71df3c",
  null
  
)

export default component.exports