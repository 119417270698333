import Vue from 'vue'

import { httpHelper } from '@/utils'

const state = {
    activeSharedTracker: null,
    activeSharedTrackerTrace: [],
    sharedTrackers: [],
    sharingConfig: null,
}

const getters = {}

const actions = {
    async loadSharingData({ commit }, { token }) {
        try {
            commit('common/setDataLoading', true, { root: true })
            const [assetsResponse, configResponse] = await Promise.all([
                httpHelper.get('/share/assets/', { params: { token } }),
                httpHelper.get('/share/config/', { params: { token } }),
            ])
            commit('setSharingConfig', configResponse.data)
            commit('setSharedTrackers', assetsResponse.data.results)
        } catch {
            commit('setSharingConfig', null)
            commit('setSharedTrackers', [])
        } finally {
            commit('common/setDataLoading', false, { root: true })
        }
    },
}

const mutations = {
    setActiveSharedTracker(state, data) {
        if (!data) {
            state.activeSharedTrackerTrace = []
        } else if (data.id !== state.activeSharedTracker?.id) {
            state.activeSharedTrackerTrace = [
                [data.position.lat, data.position.lng],
            ]
        }

        state.activeSharedTracker = data
    },
    setSharingConfig(state, data) {
        state.sharingConfig = data
    },
    setSharedTrackers(state, data) {
        state.sharedTrackers = data
    },
    updateTrackerLastContact(state, { id, time }) {
        const index = state.sharedTrackers.findIndex(
            tracker => tracker.id === id
        )
        const tracker = state.sharedTrackers[index]

        if (!tracker) {
            return
        }

        tracker.last_contact = time

        Vue.set(state.sharedTrackers, index, tracker)
    },
    updateTrackerPosition(state, { id, lat, lng, time }) {
        const index = state.sharedTrackers.findIndex(
            tracker => tracker.id === id
        )
        const tracker = state.sharedTrackers[index]

        if (!tracker) {
            return
        }

        tracker.position.lat = lat
        tracker.position.lng = lng
        tracker.position.timestamp = time

        if (state.activeSharedTracker?.id === id) {
            state.activeSharedTrackerTrace = [
                [lat, lng],
                ...state.activeSharedTrackerTrace,
            ]
        }

        Vue.set(state.sharedTrackers, index, tracker)
    },
    updateTrackerSensorData(state, { id, values, time }) {
        const index = state.sharedTrackers.findIndex(
            tracker => tracker.id === id
        )
        const tracker = state.sharedTrackers[index]

        if (!tracker) {
            return
        }

        Object.entries(values).forEach(([key, value]) => {
            tracker.sensor_data[key] = {
                value,
                last_update: time,
            }
        })

        Vue.set(state.sharedTrackers, index, tracker)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
