<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <line class="a" x1="12.002" y1="0.751" x2="12.002" y2="15.751" />

        <polyline class="a" points="7.502 11.251 12.002 15.751 16.502 11.251" />

        <path
            class="a"
            d="M15.752,4.5H18a3,3,0,0,1,3,3v12.75a3,3,0,0,1-3,3H6a3,3,0,0,1-3-3V7.5a3,3,0,0,1,3-3h2.25"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'InstallationIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
