<template>
    <VToggleButton
        v-bind="{ ...additionalProps, ...$attrs }"
        :width="40"
        :height="24"
        :speed="150"
        sync
        v-on="$listeners"
    />
</template>

<script>
import { ToggleButton as VToggleButton } from 'vue-js-toggle-button'

export default {
    name: 'ToggleButton',
    components: {
        VToggleButton,
    },
    props: {
        redesigned: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        additionalProps() {
            return this.redesigned
                ? { class: 'redesigned', cssColors: true }
                : { color: '#02a5e1' }
        },
    },
}
</script>
