import store from '@/store'
import { httpHelper } from '@/utils'

export const commonService = {
    populateData,
    populateUsers,
}

async function populateData() {
    const fetchAssetTypesPromise = fetchAssetTypes()
    const fetchLocationsPromise = fetchLocations()
    const fetchTrackersPromise = fetchTrackers()
    const requests = [
        fetchAssetTypesPromise,
        fetchLocationsPromise,
        fetchTrackersPromise,
    ]

    if (
        store.getters['auth/isAdminOrStaff'] ||
        store.getters['auth/isSimulationMode']
    ) {
        const fetchUsersPromise = fetchUsers()
        fetchUsersPromise.then(value => (store.state.user.users = value))
        requests.push(fetchUsersPromise)
    } else {
        store.state.user.users = []
    }

    if (store.getters['auth/hasMaintenanceAccess']) {
        const fetchMaintenancePoliciesPromise = fetchMaintenancePolicies()
        fetchMaintenancePoliciesPromise.then(
            value => (store.state.maintenance.maintenancePolicies = value)
        )
        requests.push(fetchMaintenancePoliciesPromise)
    } else {
        store.state.maintenance.maintenancePolicies = []
    }

    fetchAssetTypesPromise.then(
        value => (store.state.tracker.assetTypes = value)
    )
    fetchLocationsPromise.then(
        value => (store.state.location.locations = value)
    )
    fetchTrackersPromise.then(value => (store.state.tracker.trackers = value))

    return Promise.all(requests)
}

async function populateUsers() {
    if (
        store.getters['auth/isAdminOrStaff'] ||
        store.getters['auth/isSimulationMode']
    ) {
        store.state.user.users = await fetchUsers()
    }
}

async function fetchAssetTypes() {
    const { data } = await httpHelper.get('/assettypes/')

    return data.results
}

async function fetchLocations() {
    const locations = []
    let url = `locations/?limit=${process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST}`

    while (url) {
        const { data } = await httpHelper.get(url)
        locations.push(...data.results)
        url = data.next
    }

    return locations
}

async function fetchMaintenancePolicies() {
    const policies = []
    let url = `maintenance-policies/?limit=${process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST}`

    while (url) {
        const { data } = await httpHelper.get(url)
        policies.push(...data.results)
        url = data.next
    }

    return policies
}

async function fetchTrackers() {
    const trackers = []
    let url = `trackers/?limit=${process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST}`

    while (url) {
        const { data } = await httpHelper.get(url)
        trackers.push(...data.results)
        url = data.next
    }

    trackers.forEach(tracker => {
        if (tracker.asset_details.sensor_data.battery?.value >= 0) {
            tracker.asset_details.sensor_data.battery.low =
                Math.floor(tracker.asset_details.sensor_data.battery.value) <=
                store.getters['auth/batteryThreshold']
        } else if (tracker?.tracker_status?.battery >= 0) {
            tracker.asset_details.sensor_data.battery = {
                value: tracker.tracker_status.battery,
                last_update: tracker.tracker_status.last_update,
                low: tracker.tracker_status.battery_low,
            }
        }
    })

    return trackers
}

async function fetchUsers() {
    const httpConfig = await httpHelper.getOverwrittenConfig()
    const users = []
    let url = `users/?limit=${process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST}`

    while (url) {
        const { data } = await httpHelper.get(url, httpConfig)
        users.push(...data.results)
        url = data.next
    }

    return users
}
