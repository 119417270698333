<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <path class="a" d="M20.249,18.754v1.5a1.5,1.5,0,0,1-1.5,1.5h-4.5" />
        <path class="a" d="M14.249,3.754h4.5a1.5,1.5,0,0,1,1.5,1.5v1.5" />
        <path
            class="a"
            d="M10.393,23.246l-9-1.286a.75.75,0,0,1-.644-.742V3.725a.75.75,0,0,1,.593-.734l9-2.221a.75.75,0,0,1,.907.734v21A.75.75,0,0,1,10.393,23.246Z"
        />
        <polyline class="a" points="20.249 9.753 23.249 12.753 14.999 12.753" />
        <line class="a" x1="20.25" y1="15.753" x2="23.25" y2="12.753" />
        <path
            class="a"
            d="M7.874,11.629A.375.375,0,1,0,8.249,12a.374.374,0,0,0-.375-.375h0"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'LogoutIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
