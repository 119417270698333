export default {
    en: {
        router: {
            assetTypes: 'Types of animals',
        },
        shared: {
            measurements: {
                geofence: 'Location',
            },
            asset: 'Animal',
            assetCount: '{n} Animal | {n} Animals',
            assets: 'Animals',
        },
    },
    de: {
        router: {
            assetTypes: 'Typen von Tiere',
        },
        shared: {
            measurements: {
                geofence: 'Standort',
            },
            asset: 'Tier',
            assetCount: '{n} Tier | {n} Tiere',
            assets: 'Tiere',
        },
    },
    fr: {
        router: {
            assetTypes: "Types d'animaux",
        },
        shared: {
            measurements: {
                geofence: 'Emplacement',
            },
            asset: 'Animal',
            assetCount: '{n} Animal | {n} Animaux',
            assets: 'Animaux',
        },
    },
    it: {
        router: {
            assetTypes: 'Tipi di animali',
        },
        shared: {
            measurements: {
                geofence: 'Location',
            },
            asset: 'Animale',
            assetCount: '{n} Animale | {n} Animali',
            assets: 'Animali',
        },
    },
}
