<template>
    <Icon
        :color="color"
        :fill-color="fillColor"
        :width="width"
        :height="height"
        :viewbox-width="600"
        :viewbox-height="600"
        @click="$emit('click')"
    >
        <line
            x1="21.2076"
            y1="300.223"
            x2="580.793"
            y2="300.223"
            stroke-width="1.5544"
        />

        <line
            x1="0.932934"
            y1="326.472"
            x2="600.932"
            y2="273.979"
            stroke-width="1.5544"
        />

        <line
            x1="25.3233"
            y1="348.82"
            x2="576.407"
            y2="251.649"
            stroke-width="1.5544"
        />

        <line
            x1="9.91457"
            y1="378.192"
            x2="591.683"
            y2="222.307"
            stroke-width="1.5544"
        />

        <line
            x1="27.7411"
            y1="427.566"
            x2="573.602"
            y2="173.026"
            stroke-width="1.5544"
        />

        <line
            x1="37.8151"
            y1="395.965"
            x2="563.653"
            y2="204.575"
            stroke-width="1.5544"
        />

        <line
            x1="58.3038"
            y1="440.223"
            x2="542.919"
            y2="160.431"
            stroke-width="1.5544"
        />

        <line
            x1="86.1667"
            y1="480.252"
            x2="514.834"
            y2="120.558"
            stroke-width="1.5544"
        />

        <line
            x1="120.557"
            y1="514.834"
            x2="480.252"
            y2="86.1672"
            stroke-width="1.5544"
        />

        <line
            x1="160.43"
            y1="542.919"
            x2="440.223"
            y2="58.3043"
            stroke-width="1.5544"
        />

        <line
            x1="204.574"
            y1="563.654"
            x2="395.964"
            y2="37.8155"
            stroke-width="1.5544"
        />

        <line
            x1="251.649"
            y1="576.407"
            x2="348.82"
            y2="25.3234"
            stroke-width="1.5544"
        />

        <line
            x1="300.223"
            y1="580.793"
            x2="300.223"
            y2="21.2074"
            stroke-width="1.5544"
        />

        <line
            x1="348.82"
            y1="576.677"
            x2="251.649"
            y2="25.5931"
            stroke-width="1.5544"
        />

        <line
            x1="395.964"
            y1="564.185"
            x2="204.575"
            y2="38.3467"
            stroke-width="1.5544"
        />

        <line
            x1="440.223"
            y1="543.517"
            x2="160.431"
            y2="58.9018"
            stroke-width="1.5544"
        />

        <line
            x1="480.252"
            y1="515.654"
            x2="120.557"
            y2="86.9866"
            stroke-width="1.5544"
        />

        <line
            x1="514.834"
            y1="481.263"
            x2="86.1668"
            y2="121.569"
            stroke-width="1.5544"
        />

        <line
            x1="542.919"
            y1="441.39"
            x2="58.3038"
            y2="161.597"
            stroke-width="1.5544"
        />

        <line
            x1="563.653"
            y1="397.246"
            x2="37.8148"
            y2="205.856"
            stroke-width="1.5544"
        />

        <line
            x1="591.683"
            y1="379.5"
            x2="9.91426"
            y2="223.615"
            stroke-width="1.5544"
        />

        <line
            x1="576.407"
            y1="350.171"
            x2="25.3227"
            y2="253"
            stroke-width="1.5544"
        />

        <line
            x1="600.932"
            y1="327.827"
            x2="0.932462"
            y2="275.334"
            stroke-width="1.5544"
        />

        <line
            x1="573.602"
            y1="428.781"
            x2="27.741"
            y2="174.242"
            stroke-width="1.5544"
        />

        <line
            x1="87.5084"
            y1="513.393"
            x2="513.393"
            y2="87.5084"
            stroke-width="1.5544"
        />

        <line
            x1="378.192"
            y1="592.085"
            x2="222.307"
            y2="10.3166"
            stroke-width="1.5544"
        />

        <line
            x1="127.633"
            y1="547.238"
            x2="473.093"
            y2="53.8701"
            stroke-width="1.5544"
        />

        <line
            x1="427.566"
            y1="574.259"
            x2="173.026"
            y2="28.3977"
            stroke-width="1.5544"
        />

        <line
            x1="173.025"
            y1="573.602"
            x2="427.565"
            y2="27.7407"
            stroke-width="1.5544"
        />

        <line
            x1="473.093"
            y1="548.13"
            x2="127.633"
            y2="54.7617"
            stroke-width="1.5544"
        />

        <line
            x1="222.307"
            y1="591.683"
            x2="378.191"
            y2="9.91423"
            stroke-width="1.5544"
        />

        <line
            x1="513.392"
            y1="514.491"
            x2="87.5081"
            y2="88.6073"
            stroke-width="1.5544"
        />

        <line
            x1="273.979"
            y1="600.932"
            x2="326.472"
            y2="0.932218"
            stroke-width="1.5544"
        />

        <line
            x1="547.238"
            y1="474.366"
            x2="53.8699"
            y2="128.906"
            stroke-width="1.5544"
        />

        <line
            x1="53.8703"
            y1="473.093"
            x2="547.238"
            y2="127.633"
            stroke-width="1.5544"
        />

        <line
            x1="326.472"
            y1="601.067"
            x2="273.979"
            y2="1.06769"
            stroke-width="1.5544"
        />

        <circle cx="301" cy="301" r="262.694" :stroke="fillColor" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'LogoFrameIcon',
    components: {
        Icon,
    },
    props: {
        width: {
            type: [Number, String],
            default: 600,
        },
        height: {
            type: [Number, String],
            default: 600,
        },
        color: {
            type: String,
            default: '#000',
        },
        fillColor: {
            type: String,
            default: '#000',
        },
    },
}
</script>
